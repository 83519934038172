import React from 'react'
import styled from "styled-components";
import { Button as RebassButton } from 'rebass/styled-components'
import Menu from '@mui/material/Menu'
import { AlertTriangle } from 'react-feather';
import { transparentize } from 'polished'

export const DropdownButton = styled(RebassButton)`
  cursor: pointer;
  color: #06080B;
  width: 70px;
  border-radius: 24px;
  padding: 6px 0px;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50px;
    padding: 4px 0px;
    font-size: 12px;
  `};

`


export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 120,
    color: '#FFFFFF',
    background: '#0A0D12',
    border: '1px solid rgba(119, 145, 251, 0.22)',
    borderRadius: 10,
    boxShadow: '0px 4px 28px rgba(76, 111, 255, 0.17)',
    padding: '12px 6px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    }
  },
}));

export const StyledMenuText = styled.div`
  margin-left: 12px;
  font-family: "Inter var", sans-serif;

`


const BridgeCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.825rem;
  padding: 0.2rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.red1};
  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`


export function BridgeCallbackError({ error }: { error: string }) {
  return (
    <BridgeCallbackErrorInner>
      <AlertTriangle size={24} style={{ margin: 12 }} />
      <p>{error}</p>
    </BridgeCallbackErrorInner>
  )
}