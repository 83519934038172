import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import { RouteComponentProps } from 'react-router-dom'
import { BridgeChain, BridgeToken, BRIDGE_CHAINS, ChainId, HOKK_CONTRACTS } from 'constants/index'
import { ButtonPrimary } from '../../components/Button'
import { Text } from 'rebass'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { ArrowDown } from 'react-feather'
import LogoMain from '../../assets/images/logomain.png'
import LogoText from '../../assets/images/logotext.png'
import { useWalletModalToggle } from 'state/application/hooks'
import { useActiveWeb3React } from 'hooks'
import { calculateGasMargin, getBridgeContract, getBridgeObject } from 'utils'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from '@ethersproject/bignumber'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import { BridgeCallbackError } from 'components/Bridge/styled'
import ChainSelector from 'components/Bridge/ChainSelector'
import TokenSelector from 'components/Bridge/TokenSelector'
import AmountSelector from 'components/Bridge/AmountSelector'
import AddressSelector from 'components/Bridge/AddressSelector'
import { useTokenBalance } from 'hooks/useContract'
import axios from 'axios'
import { ExternalLink } from 'theme'


const MainWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 42px 0px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 600px;
  `};
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    margin-top: 0px;
  `};
  
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    #big-logo {
      display: none;
    }
  `};
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
  
`


const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};

`

const ButtonWrapper = styled.div`
  display: flex;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 24px;
  `};
`

/*
const TitleButton = styled(ButtonLight)`
  border-radius: 6px;
  padding: 13px 22px;
  margin: 0px 12px;
  filter: drop-shadow(0px 12px 25px rgba(2, 157, 149, 0.3));

`
*/

const BridgeWrapper = styled.div`
  position: relative;
`

const ChainChanger = styled.div`
  cursor: pointer;
  position: absolute;
  top: 60px;
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3050D1;
  border-radius: 50%;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 40px;
    height: 40px;
    top: 45px;
    left: calc(50% - 20px);
  `};
`

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0px;

`

const ButtonUnlock = styled(ButtonPrimary)`
  width: 160px;
  filter: drop-shadow(0px 12px 25px rgba(28, 64, 194, 0.24));
  padding: 13px 22px;
  border-radius: 24px;

`

const ButtonHistory = styled(ButtonPrimary)`
  width: 160px;
  filter: drop-shadow(0px 12px 25px rgba(28, 64, 194, 0.24));
  padding: 13px 22px;
  border-radius: 24px;
  margin: 24px 0px;

`

const BridgeGasText = styled(Text)`
  padding-top: 12px;
  text-align: center;

`

export default function Bridge({ history }: RouteComponentProps) {

  const { account, chainId, library } = useActiveWeb3React()

  const [chainA, setChainA] = useState<BridgeChain>(BridgeChain.BSC);
  const [chainB, setChainB] = useState<BridgeChain>(BridgeChain.HECO);
  const [token, setToken] = useState<BridgeToken>(BridgeToken.HOKK);
  const [amount, setAmount] = useState<number>(0);
  const [address, setAddress] = useState<string>('');
  const [bridge, setBridge] = useState<any>({});

  const balance = useTokenBalance(token, chainId, account);

  const [checkChain, setCheckChain] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')

  const [errorMessage, setErrorMessage] = useState<string>('')
  const isPending = useIsTransactionPending(txHash);
  const [gas, setGas] = useState<number>(0);

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  useEffect(() => {
    async function fetchBrigeObject() {
      let obj = await getBridgeObject();
      if (obj) {
        setBridge(obj);
      }
    }

    fetchBrigeObject();
  }, [])

  useEffect(() => {

    if (account) {
      setAddress(account);
    }

  }, [
    account
  ])

  useEffect(() => {

    if (!account) {
      setCheckChain(false);
    }
    else {

      if (chainA === BridgeChain.ETH) {
        if (chainId === 1
          || chainId === 3) {      // Ropsten
          setCheckChain(true);
        }
        else {
          setCheckChain(false);
        }
      }
      else if (chainA === BridgeChain.BSC) {
        if (chainId === 56
          || chainId === 97) {      // BSC Testnet
          setCheckChain(true);
        }
        else {
          setCheckChain(false);
        }
      }
      else if (chainA === BridgeChain.HECO) {
        if (chainId === 128
          || chainId === 256) {      // HECO Testnet
          setCheckChain(true);
        }
        else {
          setCheckChain(false);
        }
      }
    }

  }, [
    account,
    chainId,
    chainA
  ])

  useEffect(() => {
    async function fetchGas() {

      if (!account
        || !bridge
        || !address
        || amount <= 0) {
        return;
      }

      const destination = getDestination();

      const { data } = await axios.get<any>(bridge['url'] + '/validator/gas/', {
        params: {
          'endChain': BridgeChain[chainB],
          'startChain': BridgeChain[chainA],
          'sender': account,
          'recipient': address,
          'destination': destination,
          'amount': ethers.utils.parseUnits(amount.toString(), 18).toString()
        }
      })

      if (data['status'] !== 'success') {
        setGas(0);
        return;
      }

      setGas(data['totalCoinCost']);

    }

    fetchGas();
  }, [
    account,
    bridge,
    chainA,
    chainB,
    address,
    amount
  ])

  const handleChainA = useCallback((oldChain, newChain) => {

    console.log(oldChain, newChain);

    // If set same chain, exchange
    if (chainB === newChain) {
      setChainB(oldChain);
    }

    setChainA(newChain);
    setErrorMessage('');

  }, [
    chainA,
    chainB
  ])

  const handleChainB = useCallback((oldChain, newChain) => {

    console.log(oldChain, newChain);

    // If set same chain, exchange
    if (chainA === newChain) {
      setChainA(oldChain);
    }

    setChainB(newChain);
    setErrorMessage('');

  }, [
    chainA,
    chainB
  ])

  const handleToken = useCallback((_token) => {

    setToken(_token);

  }, [
    setToken
  ])

  const handleChangeChain = () => {
    let _chainA: BridgeChain = chainA;
    let _chainB: BridgeChain = chainB;

    setChainA(_chainB);
    setChainB(_chainA);
    setErrorMessage('');
  }

  const handleAmount = useCallback((_amount) => {

    setAmount(_amount);

  }, [
    setAmount
  ])

  const handleAddress = useCallback((_address) => {

    setAddress(_address);

  }, [
    setAddress
  ])

  const unlockWallet = async () => {

    if (!account) {
      toggleWalletModal();
    }
    else {

      const ethereum = window.ethereum;

      try {
        await ethereum?.request({
          method: 'wallet_switchEthereumChain',
          params: [{
            chainId: BRIDGE_CHAINS[chainA].chainId
          }],
        });
      }
      catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await ethereum?.request({
              method: 'wallet_addEthereumChain',
              params: [BRIDGE_CHAINS[chainA]],
            });
          } catch (addError) {
            setErrorMessage('Get error while add chain.');
          }
        }
        else {
          setErrorMessage('Get error while switch chain.');
        }
      }
    }

  }

  const getDestination = () => {

    let destination = undefined;

    if (chainB === BridgeChain.ETH) {
      if (chainId === ChainId.MAINNET
        || chainId === ChainId.BSCMAIN
        || chainId === ChainId.HECOMAIN) {
        destination = HOKK_CONTRACTS[ChainId.MAINNET];
      }
      else {
        destination = HOKK_CONTRACTS[ChainId.ROPSTEN];
      }
    }
    else if (chainB === BridgeChain.BSC) {
      if (chainId === ChainId.MAINNET
        || chainId === ChainId.BSCMAIN
        || chainId === ChainId.HECOMAIN) {
        destination = HOKK_CONTRACTS[ChainId.BSCMAIN];
      }
      else {
        destination = HOKK_CONTRACTS[ChainId.BSCTEST];
      }
    }
    else if (chainB === BridgeChain.HECO) {
      if (chainId === ChainId.MAINNET
        || chainId === ChainId.BSCMAIN
        || chainId === ChainId.HECOMAIN) {
        destination = HOKK_CONTRACTS[ChainId.HECOMAIN];
      }
      else {
        destination = HOKK_CONTRACTS[ChainId.HECOTEST];
      }
    }

    return destination;
  }

  // tx sending
  const addTransaction = useTransactionAdder()
  async function approve() {
    if (!chainId || !library || !account) return

    if (amount <= 0) {
      setErrorMessage('You need input amount.');
      return;
    }

    if (!bridge) {
      setErrorMessage('Bridge not ready yet.');
      return;
    }

    const contract = getBridgeContract(chainId, library, account)
    const destination = getDestination();
    if (!destination) {
      setErrorMessage('Bridge not matched.');
      return;
    }


    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null;

    estimate = contract.estimateGas.swapAcrossChain
    method = contract.swapAcrossChain
    args = [
      chainB === BridgeChain.BSC ? 'BSC' : (
        chainB === BridgeChain.ETH ? 'ETH' : (
          chainB === BridgeChain.HECO ? 'HECO' : ''
        )
      ), // endChain
      bridge['moniker'], // preferredNode,
      ethers.utils.parseUnits(amount.toString(), 18).toString(), // amount
      address, // recipient
      destination
    ]
    value = BigNumber.from(gas);

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)
          setAmount(0);

          addTransaction(response, {
            summary:
              'Swap has been successed.'
          })
          setTxHash(response.hash)
          setErrorMessage('');
        })
      )
      .catch(error => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.data) {
            setErrorMessage(error.data.message);
          }
          else {
            setErrorMessage(error.message);
          }
        }
        else {
          setErrorMessage('User rejected transaction.');
        }
      })
  }

  const getCoin = () => {

    if (chainId === ChainId.MAINNET
      || chainId === ChainId.ROPSTEN) {
      return 'ETH';
    }

    if (chainId === ChainId.BSCMAIN
      || chainId === ChainId.BSCTEST) {
      return 'BNB';
    }

    if (chainId === ChainId.HECOMAIN
      || chainId === ChainId.HECOTEST) {
      return 'HT';
    }

    return '';
  }

  return (
    <>

      <LogoWrapper>

        <img src={LogoMain} alt={'HOKKFi Logo'} width={150} height={100} />

        <img src={LogoText} alt={'HOKKFi Text'} width={640} height={150} id="big-logo" />

      </LogoWrapper>

      <MainWrapper>

        <TitleWrapper>

          <Text fontWeight={500} fontSize={20}>
            Cross-Chain Token Bridge
          </Text>

          <ButtonWrapper>

            {
              /*
                <TitleButton
                  as={Link}
                  to={'/bridge'}>
                  Bridge
                </TitleButton>

                <TitleButton as="a" href="https://hokkfi.com/" target="_blank">
                  Exchange
                </TitleButton>
              */
            }

          </ButtonWrapper>

        </TitleWrapper>

        <BridgeWrapper>

          <ChainSelector
            chainId={chainA}
            onChange={handleChainA}
          />

          <ChainChanger
            onClick={handleChangeChain}
          >
            <ArrowDown fontSize={18} />
          </ChainChanger>

          <ChainSelector
            chainId={chainB}
            onChange={handleChainB}
          />

          <TokenSelector
            token={token}
            onChange={handleToken}
          />

          {
            checkChain ? <>

              <AddressSelector
                address={address}
                onChange={handleAddress}
              />

              <AmountSelector
                amount={amount}
                symbol={BridgeToken[token]}
                balance={balance}
                onChange={handleAmount}
              />

              < BridgeGasText >
                Transaction & gas costs for the bridge usage will be {ethers.utils.formatUnits(gas).substr(0, 5)} {getCoin()}
              </BridgeGasText>

            </> : null
          }

          {errorMessage ? <BridgeCallbackError error={errorMessage} /> : null}

        </BridgeWrapper>

        <FooterWrapper>

          {
            checkChain ?
              <div>
                <ButtonHistory onClick={approve} disabled={isPending || attemptingTxn}>
                  {isPending ? 'Pending' : 'Confirm'}
                </ButtonHistory>
                <ButtonHistory
                  as={Link}
                  to={'/history'}
                >
                  History
                </ButtonHistory>
              </div>
              :
              <ButtonUnlock onClick={unlockWallet}>
                Unlock Wallet
              </ButtonUnlock>
          }

        </FooterWrapper>

        {
          isMobile ?
            <Text textAlign="center" marginBottom="18px">
              We recommend using the bridge on a desktop/laptop for the best user experience
            </Text> : null
        }

        <Text textAlign="center">
          For HOKK Bridge Support Please Reach Out To: <ExternalLink href="https://t.me/joinchat/9iz8X4hu1ypmYjhh">https://t.me/joinchat/9iz8X4hu1ypmYjhh</ExternalLink>
        </Text>

      </MainWrapper>
    </>
  )
}
