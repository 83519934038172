import { Input } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: #0F131A;
  width: 100%;
  padding: 16px 32px;
  margin: 0px;
  border-radius: 60px;
  font-size: 20px;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    padding: 8px 24px;
  `};

`

const AddressText = styled.div`
  font-size: 14px;

`

const AddressInput = styled(Input)`
  width: 100%;

  & input {
    font-size: 18px;
    font-family: "Inter var", sans-serif;
  }

`

export default function AddressSelector({
  address,
  onChange
}: {
  address: string,
  onChange: (address_: string) => void
}) {

  const changeAddress = (e: any) => {
    var _address = e.target.value;
    onChange(_address);

    address = _address;
  }

  return (
    <Wrapper style={{ marginTop: '24px' }}>

      <AddressText>
        Enter recipient address:
      </AddressText>

      <AddressInput style={{ color: 'white' }} value={address} onChange={changeAddress} placeholder="0x...." />

    </Wrapper>
  )

}
