import { ChainId } from 'constants/index'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',

  [ChainId.BSCMAIN]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.BSCTEST]: '0x6e5BB1a5Ad6F68A8D7D6A5e47750eC15773d6042',

  [ChainId.HECOMAIN]: '0x2C55D51804CF5b436BA5AF37bD7b8E5DB70EBf29',
  [ChainId.HECOTEST]: '0x01A4bFec8Cfd2580640fc6Bd0CB11461a6C804f1',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
