import { BigNumber } from '@ethersproject/bignumber'
import { JSBI, Percent } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'

export const ROUTER_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export { PRELOADED_PROPOSALS } from './proposals'

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,

  BSCMAIN = 56,
  BSCTEST = 97,

  HECOMAIN = 128,
  HECOTEST = 256
}


// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 400
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C'
]


export enum BridgeChain {
  ETH,
  BSC,
  HECO
};


export enum BridgeToken {
  HOKK
};

export const HOKK_CONTRACTS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: "0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8",
  [ChainId.ROPSTEN]: "0x371beb307f656620006C01ef4a7c5e63d3f2536f",

  [ChainId.BSCMAIN]: "0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8",
  [ChainId.BSCTEST]: "0x586E860f864E6184C88bef7b9aDbcBBf5E7cadeC",

  [ChainId.HECOMAIN]: "0x4ebf49cc2d2dd029e5cfdf868316385dffd94c6a",
  [ChainId.HECOTEST]: "0xa325E164407C4bFC20bF584073eb4bB4d4665b76"
}


export interface TransactionType {
  _id: string,
  orderID: number,
  orderIDVotes: Array<string>,
  detectionTime: Date,
  hash: string,
  endHash: string,
  blockNumber: number,
  signedCount: number,
  signers: Array<string>,
  startChain: string,
  startChainIndex: number,
  endChain: string,
  sender: string,
  recipient: string,
  destination: string,
  amount: BigNumber,
  confirmed: boolean,
  moniker: string,
}

export const VALIDATOR_URL = 'https://hokkbridge.link/validator/';

/*
export const BRIDGE_CHAINS: { [chainId in BridgeChain]: any } = {
  [BridgeChain.ETH]: {
    chainId: "0x3",
    chainName: "Ropsten Testnet",
    rpcUrls: ["https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://ropsten.etherscan.io"],
  },
  [BridgeChain.BSC]: {
    chainId: "0x61",
    chainName: "BSC Testnet",
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    nativeCurrency: {
      name: "BINANCE COIN",
      symbol: "BNB",
      decimals: 18,
    },
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
  [BridgeChain.HECO]: {
    chainId: "0x100",
    chainName: "HECO Testnet",
    rpcUrls: ["https://http-testnet.hecochain.com"],
    nativeCurrency: {
      name: "HECO COIN",
      symbol: "HT",
      decimals: 18,
    },
    blockExplorerUrls: ["https://testnet.hecoinfo.com"],
  }
}
*/


export const BRIDGE_CHAINS: { [chainId in BridgeChain]: any } = {
  [BridgeChain.ETH]: {
    chainId: "0x1",
  },
  [BridgeChain.BSC]: {
    chainId: "0x38",
    chainName: "BSC Mainnet",
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    nativeCurrency: {
      name: "BINANCE COIN",
      symbol: "BNB",
      decimals: 18,
    },
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [BridgeChain.HECO]: {
    chainId: "0x80",
    chainName: "HECO Mainnet",
    rpcUrls: ["https://http-mainnet.hecochain.com"],
    nativeCurrency: {
      name: "HECO COIN",
      symbol: "HT",
      decimals: 18,
    },
    blockExplorerUrls: ["https://scan.hecochain.com"],
  }
}