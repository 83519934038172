import { Input } from '@mui/material'
import { CurrencyAmount, Rounding } from '@uniswap/sdk'
import { ButtonPrimary } from 'components/Button'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: #0F131A;
  width: 100%;
  padding: 16px 32px;
  margin: 0px;
  border-radius: 60px;
  font-size: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    padding: 8px 24px;
  `};

`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;

`

const InputWrapper = styled.div`
  display: flex;
  margin-top: 8px;

`


const AmountText = styled.div`
  font-size: 14px;

`

const AmountInput = styled(Input)`
  width: 100%;

  & input {
    font-size: 18px;
  }

`
const ButtonMax = styled(ButtonPrimary)`
  width: 80px;
  filter: drop-shadow(0px 12px 25px rgba(28, 64, 194, 0.24));
  margin-left: 24px;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 24 px;

`

export default function AmountSelector({
  amount,
  balance,
  symbol,
  onChange
}: {
  amount: number,
  balance: CurrencyAmount | undefined,
  symbol: string,
  onChange: (amount_: number) => void
}) {

  const changeAmount = (e: any) => {
    var _amount = e.target.value;
    onChange(_amount);
  }

  const maxBalance = () => {
    onChange(parseFloat(balance ? balance.toFixed(2, undefined, Rounding.ROUND_DOWN) : ''));
  }

  return (
    <Wrapper style={{ marginTop: '24px' }}>

      <LabelWrapper>
        <AmountText>
          Enter amount:
        </AmountText>
        <AmountText>
          Balance: {balance?.toFixed(2)} {symbol}
        </AmountText>
      </LabelWrapper>

      <InputWrapper>

        <AmountInput type="number" style={{ color: 'white' }} value={amount} onChange={changeAmount} placeholder="0.0" />
        <ButtonMax onClick={maxBalance}>
          MAX
        </ButtonMax>

      </InputWrapper>

    </Wrapper>
  )

}
