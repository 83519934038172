import { Checkbox, CheckboxProps } from '@mui/material';
import { ButtonBlue } from 'components/Button';
import { TransactionType } from 'constants/index';
import { useActiveWeb3React } from 'hooks';
import React, { useEffect, useState } from 'react'
import { DownloadCloud } from 'react-feather';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import axios from 'axios';
import styled from 'styled-components';
import useTheme from '../../hooks/useTheme'
import { ButtonEmpty } from '../../components/Button'
import { ArrowBack } from '@material-ui/icons';
import { getBridgeObject, tryParseAmount2 } from 'utils';
import moment from 'moment';

const MainWrapper = styled.div`
  width: calc(100% - 42px);
  margin-top: 32px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin-top: 0px;
  `};

`

const TitleWrapper = styled(Text)`
  font-size: 24px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
  `};

`

const TransactionsWrapper = styled.div`
  margin-top: 32px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const MobileTransWrapper = styled.div`

  display: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `};

`


const TransactionRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr 120px 120px 1fr 1fr 100px 1fr;
  background: rgba(21, 21, 33, 0.74);
  backdrop-filter: blur(7px);
  border-radius: 10px;
  padding: 16px;
  margin: 16px 0px;

`

const TransactionHeader = styled(TransactionRow)`
  background: rgba(21, 21, 33, 0.74);
  color: #92929D;
  padding: 8px 16px;

`

const MobileTransHeader = styled(TransactionHeader)`
  grid-template-columns: 40px 2fr 1fr 1fr 1fr;

`

const MobileTransRow = styled.div`
  font-size: 14px;
  background: rgba(21, 21, 33, 0.74);
  backdrop-filter: blur(7px);
  border-radius: 10px;
  padding: 8px;
  margin: 16px 0px;

  .primary {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 2fr 1fr 1fr 1fr;
  }

  .secondary {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 8px;
  }

`

const ButtonDetailed = styled(ButtonBlue)`
  width: 50px;
  padding: 10px;
  border-radius: 10px;
  margin-left: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 40px;
    padding: 5px;
    border-radius: 5px;
  `};

`

const ButtonBack = styled(ButtonEmpty)`
  padding: 0px;
  margin: 0px;
  color: white;

`

function StyledCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        'color': '#92929D',
      }}
      disableRipple
      color="default"
      {...props}
    />
  )
}

export default function History({ history }: RouteComponentProps) {

  const theme = useTheme()

  const { account } = useActiveWeb3React()

  const [transactions, setTransactions] = useState<Array<TransactionType>>([]);

  useEffect(() => {
    const getTransactions = async () => {
      if (!account) {
        return;
      }

      const bridge = await getBridgeObject();
      if (!bridge) {
        return;
      }

      const { data } = await axios.get<any>(bridge['url'] + '/history/' + account)
      if (data.transactions) {
        setTransactions(data.transactions);
      }

    }
    getTransactions();
  }, [
    account
  ]);

  const shortenTxId = (txId: string) => {
    return `${txId.substring(0, 5)}...${txId.substr(-3, 3)}`
  }


  return (
    <>

      <MainWrapper>

        <TitleWrapper display="flex">

          <ButtonBack
            width="120px"
            as={Link}
            to={'/bridge'}
          >
            <ArrowBack />
          </ButtonBack>

          <Text flex="1">
            History
          </Text>

        </TitleWrapper>

        <TransactionsWrapper>

          <TransactionHeader>
            <StyledCheckbox />
            <Text>Order ID </Text>
            <Text>From</Text>
            <Text>To</Text>
            <Text>Amount</Text>
            <Text>Date / Time</Text>
            <Text>Status</Text>
            <Text>Transaction</Text>
          </TransactionHeader>

          {transactions.map((transaction) => (
            <TransactionRow key={transaction.orderID}>
              <StyledCheckbox />
              <Text>#{transaction.orderID} </Text>
              <Text>{transaction.startChain}</Text>
              <Text>{transaction.endChain}</Text>
              <Text>{tryParseAmount2(transaction.amount)?.toExact()} HOKK</Text>
              <Text>{moment(transaction.detectionTime).format('LLL')}</Text>
              <Text color={transaction.confirmed ? theme.success : theme.red1}>{transaction.confirmed ? 'Confirmed' : 'Pending'}</Text>
              <Flex alignItems="center">
                <Text width="120px">{shortenTxId(transaction.hash)}</Text>
                <ButtonDetailed
                  as={Link}
                  to={{
                    pathname: '/detail/' + transaction.hash,
                    state: transaction
                  }}
                >
                  <DownloadCloud />
                </ButtonDetailed>
              </Flex>
            </TransactionRow>
          ))}

        </TransactionsWrapper>

        <MobileTransWrapper>

          <MobileTransHeader>
            <StyledCheckbox />
            <Text>Order ID </Text>
            <Text>From</Text>
            <Text>To</Text>
            <Text>Amount</Text>
          </MobileTransHeader>

          {transactions.map((transaction) => (
            <MobileTransRow key={transaction.orderID}>
              <div className="primary">
                <StyledCheckbox />
                <Text>#{transaction.orderID} </Text>
                <Text>{transaction.startChain}</Text>
                <Text>{transaction.endChain}</Text>
                <Text>{tryParseAmount2(transaction.amount)?.toExact()}</Text>
              </div>
              <div className="secondary">
                <Text color={transaction.confirmed ? theme.success : theme.red1} marginLeft={16}>{transaction.confirmed ? 'Confirmed' : 'Pending'}</Text>
                <Text>{shortenTxId(transaction.hash)}</Text>
                <ButtonDetailed
                  as={Link}
                  to={'/detail/' + transaction.hash}
                >
                  <DownloadCloud />
                </ButtonDetailed>
              </div>
            </MobileTransRow>
          ))}

        </MobileTransWrapper>

      </MainWrapper>

    </>
  )
}
