import useTheme from 'hooks/useTheme';
import React from 'react'
import { File, Clock, HelpCircle, CheckCircle, Info } from 'react-feather';
import { RouteComponentProps } from 'react-router';
import { Text } from 'rebass';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { tryParseAmount2 } from 'utils';
import moment from 'moment';
import { ArrowBack } from '@material-ui/icons';
import { ButtonEmpty } from '../../components/Button'
import { Link, Redirect } from 'react-router-dom'
import { ExternalLink } from '../../theme';



const MainWrapper = styled.div`
  width: calc(100% - 42px);
  margin-top: 32px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin-top: 0px;
  `};

`

const TitleWrapper = styled(Text)`
  font-size: 24px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
  `};

`

const DetailWrapper = styled.div`
  margin-top: 24px;
  background: rgba(11, 11, 16, 0.72);
  border-radius: 10px;
  padding: 24px;

`

const DetailRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr 2fr;
  grid-row-gap: 10px;
  padding: 24px 0px;
  border-bottom: 1px dashed #323438;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    &#transactionHashRow, 
    &#timestampRow,
    &#fromRow,,
    &#toRow {
      grid-template-columns: 30px 1fr;
    }
  `};

`

const InfoSection = styled.div`

  display: flex;

  span {
    overflow-wrap: break-word;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-column: 1 / span 2;
  `};
  
`

const ButtonBack = styled(ButtonEmpty)`
  padding: 0px;
  margin: 0px;
  color: white;

`


export default function Detail({
  match: {
    params: { txId }
  }
}: RouteComponentProps<{ txId: string }>) {

  const theme = useTheme();
  const location = useLocation();
  const transaction: any | undefined = location.state;
  if (!transaction) {
    return <Redirect to='/history' />;
  }

  const getLink = (chain: string | undefined, hash: string | undefined) => {

    if (chain === 'ETH') {
      return 'https://etherscan.io/tx/' + hash;
    }

    if (chain === 'BSC') {
      return 'https://bscscan.com//tx/' + hash;
    }

    if (chain === 'HECO') {
      return 'https://hecoinfo.com/tx/' + hash;
    }

    return '';
  }

  return (
    <>

      <MainWrapper>

        <TitleWrapper display="flex">

          <ButtonBack
            width="120px"
            as={Link}
            to={'/history'}
          >
            <ArrowBack />
          </ButtonBack>

          <Text flex="1">
            Transaction Details
          </Text>

        </TitleWrapper>

        <DetailWrapper>

          <DetailRow id="transactionHashRow">
            <HelpCircle color={theme.primaryText3} />
            <Text>Transaction Hash:</Text>
            <InfoSection>
              <span> {transaction?.hash} </span>

              <ExternalLink

                href={getLink(transaction?.startChain, transaction?.hash)}>
                <File color={theme.primaryText3} size="16px" />
              </ExternalLink>

            </InfoSection>
          </DetailRow>

          <DetailRow>
            <HelpCircle color={theme.primaryText3} />
            <Text>Status:</Text>
            <div>

              {
                transaction?.confirmed ? <>
                  <span> Success </span>
                  <CheckCircle color={theme.success} size="16px" />
                </> : <>
                  <span> Pending </span>
                  <Info color={theme.red1} size="16px" />
                </>
              }

            </div>
          </DetailRow>

          <DetailRow id="blockRow">
            <HelpCircle color={theme.primaryText3} />
            <Text>Block:</Text>
            <Text>{transaction?.blockNumber}</Text>
          </DetailRow>

          <DetailRow id="timestampRow">
            <HelpCircle color={theme.primaryText3} />
            <Text>Timestamp:</Text>
            <InfoSection>
              <Clock color={theme.primaryText3} size="16px" />
              <span> {moment(transaction?.detectionTime).fromNow()} ({transaction?.detectionTime}) </span>
            </InfoSection>
          </DetailRow>

          <DetailRow id="fromRow">
            <HelpCircle color={theme.primaryText3} />
            <Text>From:</Text>
            <InfoSection>
              <span> {transaction?.startChain} </span>

              <ExternalLink

                href={getLink(transaction?.startChain, transaction?.hash)}>
                <File color={theme.primaryText3} size="16px" />
              </ExternalLink>

            </InfoSection>
          </DetailRow>

          <DetailRow id="toRow">
            <HelpCircle color={theme.primaryText3} />
            <Text>To:</Text>
            <InfoSection>
              <span> {transaction?.endChain} </span>

              <ExternalLink

                href={getLink(transaction?.endChain, transaction?.endHash)}>
                <File color={theme.primaryText3} size="16px" />
              </ExternalLink>

            </InfoSection>
          </DetailRow>

          <DetailRow>
            <HelpCircle color={theme.primaryText3} />
            <Text>Value:</Text>
            <Text>
              {tryParseAmount2(transaction?.amount)?.toExact()} HOKK
            </Text>
          </DetailRow>

          <DetailRow>
            <HelpCircle color={theme.primaryText3} />
            <Text>Transaction Fee:</Text>
            <Text>
              0.000000 HT
            </Text>
          </DetailRow>

        </DetailWrapper>

      </MainWrapper>

    </>
  )
}
