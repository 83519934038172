import React from 'react'
import { Menu as MenuIcon } from 'react-feather'
import styled from 'styled-components'
import { darken } from 'polished'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

const ToggleButton = styled.button`
  border: none;
  margin: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.primary1};
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;  
  color: #FFFFFF;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
`

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 120,
    color: '#FFFFFF',
    background: '#0A0D12',
    border: '1px solid rgba(119, 145, 251, 0.22)',
    borderRadius: 10,
    boxShadow: '0px 4px 28px rgba(76, 111, 255, 0.17)',
    padding: '12px 6px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    }
  },
}));

const StyledMenuText = styled.div`
  margin-left: 12px;
  font-family: "Inter var", sans-serif;

`


export default function MobileMenu() {

  return (
    <>
      <PopupState variant="popover">
        {(popupState) => (
          <>
            <ToggleButton
              {...bindTrigger(popupState)}
            >
              <MenuIcon size={24} />
            </ToggleButton>
            <StyledMenu {...bindMenu(popupState)}>
              <MenuItem onClick={popupState.close}>
                <StyledMenuText> Home </StyledMenuText>
              </MenuItem>
              <MenuItem onClick={popupState.close}>
                <StyledMenuText> Bridge </StyledMenuText>
              </MenuItem>
            </StyledMenu>
          </>
        )}
      </PopupState>
    </>
  )
}
